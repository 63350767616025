import React from "react";

const SvgBreezyBlob = props => (
  <svg width={575} height={479} {...props}>
    <path
      d="M100.905 457.174c26.655 16.331 58.347 24.124 88.85 20.123 29.934-3.927 57.408-17.87 86.683-25.006 38.858-9.471 79.887-6.738 119.434-13.07 49.555-7.934 96.414-30.429 133.464-64.076 23.262-21.122 43.49-48.63 45.323-80.557 1.903-33.162-16.226-64.512-36.436-91.236-20.209-26.723-43.59-52.045-55.392-83.382-8.62-22.887-10.692-48.174-22.545-69.592C441.92 17.187 402.426-.218 365.273.32c-37.154.54-72.174 16.357-103.39 36.04-31.216 19.684-59.763 43.445-91.408 62.43-28.215 16.925-76.51 26.604-102.368 34.403-27.028 8.151-55.957 22.63-64.804 49.736-5.409 16.573-1.611 34.828 2.22 52.07 7.094 31.917 24.241 55.789 31.334 87.705 5.608 25.236 1.302 58.9 13.568 81.74 11.705 21.795 29.537 37.143 50.48 49.975"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

export default SvgBreezyBlob;
