import React from "react"
import { Link } from "gatsby"
import "./related_story.scss"

const RelatedStory = props => {
  const stories = props.stories
  return (
    <div className="columns is-centered related-story">
      {stories.map(({ node }, index) =>
        index === 0 ? (
          <div className="column" key={index}>
            <Link
              to={`/news${node.path}`}
              css={{
                textDecoration: `none`,
              }}
            >
              <div className="columns">
                {node.featured_media && (
                  <img
                    src={node.featured_media.source_url}
                    className="column"
                    alt=""
                  />
                )}

                <div className="column story-content">
                  <div className="outer">
                    <div className="inner">
                      <p className="has-text is-uppercase has-text-weight-bold story-category">
                        {node.categories[1].name}
                      </p>

                      <h2
                        className="has-text-white"
                        dangerouslySetInnerHTML={{
                          __html: node.title,
                        }}
                      />
                      <div
                        className="has-text-white story-excerpt"
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt,
                        }}
                      />
                      <p className="has-text-white time-to-read">
                        {node.acf.time_to_read} read
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ) : (
          false
        )
      )}
    </div>
  )
}

export default RelatedStory
