import React, {Component} from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import SEO from "../components/seo";
import Layout from "../components/layout"
import RelatedStory from "../components/news/related_story"
import ProductCell from "../components/product/productCell"
import SvgFloatingBreezyBlob from "../components/product/svgFloatingBreezyBlob";

class IngredientTemplate extends Component {
  render() {
    const ingredient = this.props.data.wordpressPost
    const products = this.props.data.allWcProducts.edges

    const stories = this.props.data.allWordpressPost.edges.filter(function(e) {
      return e.node.categories[0].name === "News"
    });
    const helmetMeta = [
      {
        name: `title`,
        content:ingredient.yoast_meta.yoast_wpseo_title,
      },
      {
        name: `keywords`,
        content: ingredient.yoast_meta.yoast_wpseo_focuskw,
      }
    ]
    return (
      <Layout isAlternate={true}>
      { ingredient.yoast_meta.yoast_wpseo_title !== ""?
      <SEO title={ingredient.yoast_meta.yoast_wpseo_title} meta={helmetMeta}/>
      :
      false
      }
      <section className="hero has-yellow-background has-absolute-navbar">
        <div className="hero-body">

          <div className="container">
            <div className="columns is-centered is-vcentered">
              <div className="column is-6">
                {ingredient.featured_media.source_url && <img src={ingredient.featured_media.source_url} alt={ingredient.featured_media.alt_text}/>}
              </div>
              <div className="column is-5">

                <h1 className="has-text-white">
                  {ingredient.title}
                </h1>

                <br/>
                <p className="has-text-white">
                  Found in:<br/><span className="has-text-weight-bold">{ingredient.acf.found_in && ingredient.acf.found_in.map((x) => (<span>{x}<br/></span>))}</span>
                </p>

              </div>
            </div>

          </div>
        </div>

      </section>
      <section className="section is-medium">
        <SvgFloatingBreezyBlob className="svg-breezy-blob" style={{position:'absolute', right:'0'}}/>
        <div className="container">
        <div className="columns is-centered">
        <div className="column is-5">
          <h2 className="has-text-centered">
            Benefits
          </h2>
          <p className="benefits-desp">
          {ingredient.acf.benefits_desp}
          </p>
        </div>
        <br/>
        <br/>
        </div>
        <div className="columns is-centered">
        <div className="column is-3 has-text-centered">
          <img src={ingredient.acf.first_benefit_image && ingredient.acf.first_benefit_image.source_url} style={{height:"72px"}} alt=""/>
          <br/>
          <h4 className="benefits-item">
            {ingredient.acf.first_benefit}
          </h4>
        </div>
        <div className="column is-3 has-text-centered">
          <img src={ingredient.acf.second_benefit_image && ingredient.acf.second_benefit_image.source_url} style={{height:"72px"}} alt=""/>
          <br/>
          <h4 className="benefits-item">
            {ingredient.acf.second_benefit}
          </h4>
        </div>
        <div className="column is-3 has-text-centered">
          <img src={ingredient.acf.third_benefit_image && ingredient.acf.third_benefit_image.source_url} style={{height:"72px"}} alt=""/>
          <br/>
          <h4 className="benefits-item">
            {ingredient.acf.third_benefit}
          </h4>
        </div>
        </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
        <div className="columns">
        <div className="column is-5">
        <div className="article-content" dangerouslySetInnerHTML={{
          __html: ingredient.content
        }}/>
        </div>

        </div>
        </div>
        </section>

        <section className="section">
          <div className="container">

                <RelatedStory stories={stories}/>
                    </div>

                </section>

                <section className="section">
                  <div className="container">
                  <h1 className="has-text-centered" style={{marginTop:'100px'}}>Here's your<br/>Reason to Smile</h1>
                  <br/>
                    <div className="columns is-multiline is-centered is-mobile">

                      {products.map(({ node}, key) => (
                        <ProductCell product={node} key={key}/>
                      ))}
                    </div>

                  </div>

                </section>
      </Layout>
    )
  }
}

IngredientTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
}

export default IngredientTemplate

export const pageQuery = graphql `
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_focuskw
        yoast_wpseo_metadesc
      }
      content
      featured_media {
        source_url
        alt_text
      }
      acf {
        title
        first_benefit_image {
          source_url
        }
        first_benefit
        second_benefit_image {
          source_url
        }
        second_benefit
        third_benefit_image {
          source_url
        }
        third_benefit
        found_in
        benefits_desp
      }
    }
    allWordpressPost {
      edges {
        node {
          title
          excerpt
          slug
          path
          featured_media {
              id
              source_url
            }
          categories {
            name
          }
          acf {
            time_to_read
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, tags: {elemMatch: {name: {eq: "CBD"}}}}, limit: 4) {
      edges {
        node {
          id
          type
          slug
          name
          price
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          grouped_products_nodes {
            id
            wordpress_id
            type
            price
          }
        }
      }
    }
  }
`
